import React from "react"
import StaffLogo from "../images/staff.svg"

const Staff = props => (
  <div
    className="h-20 w-20 fixed bottom-0 right-0 mr-8 mb-8 rounded-full flex items-center justify-center bg-pantone p-4"
    style={{ zIndex: "100000" }}
  >
    <a
      href={process.env.GATSBY_API_URL + props.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={StaffLogo} alt="Koala Bear" />
    </a>
  </div>
)

export default Staff
