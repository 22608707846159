import React from "react"
import { Link } from "gatsby"

import P from "../../components/p"
import Breadcrumbs from "./components/Breadcrumbs"

const Hero = props => (
  <>
    <div
      className={`hero bg-base bg-${props.subject}-light w-full flex flex-col justify-center h-full hero-bg`}
    >
      <div className="container mx-auto">
        <Breadcrumbs crumbs={props.crumbs} className="pb-4" />
        <h1 className="text-white">{props.title}</h1>
        {props.subTitle && <h2 className="text-white">{props.subTitle}</h2>}
        <div className="w-full md:w-3/5 text-white text-lg hero-description">
          <P content={props.description} />
        </div>
      </div>
      <style jsx>
        {`
          .hero-bg {
            background-image: url(${props.hero_image});

            /* Set a specific height */
            height: 50%;

            /* Position and center the image to scale nicely on all screens */
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
          }
          .top-80 {
            top: 80px;
            z-index: 100000;
          }
        `}
      </style>
    </div>
    {props.download_url && (
      <div className={`sticky top-80 bg-${props.subject}-light w-full`}>
        <div className={`sticky top-80  container mx-auto py-2`}>
          <button
            className={`download-btn bg-${props.subject}-pale ${props.subject}`}
          >
            <Link to={props.download_url} className={`text-white`}>
              Download {props.download_title}
            </Link>
          </button>
          <div></div>
        </div>
      </div>
    )}
  </>
)

export default Hero
