import React from "react"
import { Link } from "gatsby"

const Breadcrumbs = props => (
  <>
    {props.crumbs && (
      <div>
        {Object.keys(props.crumbs).map((obj, i) => {
          return (
            <span key={i}>
              {props.crumbs[obj].active ? (
                <span className="breadcrumb my-0">
                  {props.crumbs[obj].title}
                </span>
              ) : (
                <span>
                  <Link
                    to={props.crumbs[obj].slug}
                    className="breadcrumb underline"
                  >
                    {props.crumbs[obj].title}
                  </Link>
                  <span className="breadcrumb my-0 mx-2">/</span>
                </span>
              )}
            </span>
          )
        })}
      </div>
    )}
  </>
)

export default Breadcrumbs
