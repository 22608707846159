import React from "react"

class P extends React.Component {
  render() {
    return (
      <p>
        <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
      </p>
    )
  }
}
export default P
