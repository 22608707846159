import React from "react"

import { Link } from "gatsby"

import P from "../p"

const UnitCards = props => (
  <Link
    to={props.url}
    className="w-full sm:w-1/2 md:w-1/3 p-2 cursor-pointer flex h-auto"
  >
    <div className="flex flex-col shadow-xl flex-1">
      <div
        className={`flex flex-row items-center border-${props.subject.toLowerCase()} border-2 bg-${props.subject.toLowerCase()}-pale flex-grow flex-1 unit-top`}
      >
        <div
          className={`bg-${props.subject.toLowerCase()} flex-1 w-1/4 h-full flex flex-col items-center`}
        >
          <p className="unit-number">{props.order}</p>
        </div>
        <div className="w-3/4">
          <h2 className="font-bold unit-text m-4">{props.full_name}</h2>
        </div>
      </div>
      <div
        className={`w-full text-left  flex flex-1 border-${props.subject.toLowerCase()} border-b-2 border-l-2 border-r-2 p-4 justify-center`}
      >
        <P content={props.short_description} />
      </div>
      <div
        className={`bg-${props.subject.toLowerCase()} p-6 w-full text-center`}
      >
        <h3 className="text-white number-lessons uppercase">
          {props.lesson_count} Lessons
        </h3>
      </div>
    </div>
  </Link>
)

export default UnitCards
