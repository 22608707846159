import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import P from "../components/p"
import Staff from "../components/staff"
import UnitCards from "../components/UnitCards"

const Course = ({ pageContext: { course } }) => (
  <Layout>
    <Staff link={course.staff_url} />
    <SEO title={course.full_name} description={course.meta_description} />
    <Hero
      subject={course.subject_name.toLowerCase()}
      title={course.full_name}
      description={course.short_description}
      hero_image={course.hero}
    />
    <div
      className={`container mx-auto py-8 ${course.subject_name.toLowerCase()} course flex flex-col`}
    >
      <div class="flex flex-row w-full">
        <div className="w-full md:w-4/5">
          <h1>{course.full_name}</h1>
          <P content={course.summary} />
        </div>
        <div className="w-full md:w-1/5 flex flex-col">Test</div>
      </div>
      <div className="flex flex-wrap w-full -mx-2">
        {Object.entries(course.units).map((obj, index) => {
          return (
            <UnitCards
              key={index}
              full_name={obj[1].full_name}
              subject={obj[1].subject_name}
              order={obj[1].order}
              url={obj[1].url}
              short_description={obj[1].short_description}
              lesson_count={obj[1].lesson_count}
            />
          )
        })}
      </div>
    </div>
  </Layout>
)

export default Course
